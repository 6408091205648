import styled from 'styled-components';
import { device, frameHeight, getSpacing } from 'stylesheet';

export const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${device.tabletMax} {
    flex-wrap: wrap;
    background-color: #fffff7;
    position: fixed;
    width: 100%;
    z-index: 10;
  }

  @media ${device.laptopMin} {
    padding: ${getSpacing(2)} ${getSpacing(2)} ${getSpacing(2)} ${getSpacing(5)};
    height: ${frameHeight.header};
  }

  @media ${device.tabletMin} and ${device.laptopMax} {
    flex-direction: row;
  }
`;
export const LogoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const SelectionContainer = styled.div`
  @media ${device.tabletMax} {
    width: 50%;
  }
`;

export const HeaderBrandImageContainer = styled.div`
  margin: 0 5px;
  height: ${frameHeight.header};
  padding: ${getSpacing(2)} 0;
`;

export const HeaderBrandImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

export const CreateHamBurgerButtonContainer = styled.div`
  @media ${device.tabletMin} {
    display: none;
  }
`;
