import { css } from 'styled-components';

/**
 * This file is here to ensure UI consistency
 * You **MUST** sync with your designer at the start of the project to check
 * what colors/typos/grid unit you are going to use, and stick to it.
 */

/**
 * App spacing measurement convention
 * Use the getSpacing function below to compute padding and margin
 * and elements with fixed width/height
 */
const SPACING_UNIT = 8;
const MEASUREMENT_UNIT = 'px';
/** created size for mobile responsiveness */
const size = {
  mobileS: '321px',
  mobileM: '375px',
  mobileL: '425px',
  mobileMix: '540px',
  mobileSmall: '340px',
  mobileMedium: '400px',
  tablet: '769px',
  laptop: '1024px',
  laptopL: '1440px',
  laptopMix: '1235px',
  desktop: '2560px',
};
export const device = {
  mobileMin: `(min-width: ${size.mobileMix})`,
  mobileMax: `(max-width: ${size.mobileMix})`,
  mobileSmall_Max: `(max-width: ${size.mobileS})`,
  mobileSmall_Min: `(min-width: ${size.mobileS})`,
  mobileMedium_Max: `(max-width: ${size.mobileMedium})`,
  mobileMedium_Min: `(min-width: ${size.mobileMedium})`,
  tabletMin: `(min-width: ${size.tablet})`,
  tabletMax: `(max-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  laptopMax: `(max-width: ${size.laptopMix})`,
  laptopMin: `(min-width: ${size.laptopMix})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktop})`,
};

export const getSpacing = (multiplier: number): string =>
  `${multiplier * SPACING_UNIT}${MEASUREMENT_UNIT}`;

export const getSizeInPx = (size: number) => `${size}px`;

/**
 * Use this palette in your components
 * If a new color is in the mockups, check with the designer
 * that the project really need a new color and add it here.
 * As to naming, the best name is the name used by the designer
 */
export const colorPalette = {
  // White variations
  white: '#FFFFFF',
  backgroundMain: '#F4F6FA',
  // Grey variations
  grey_100: '#E9E9E9',
  grey_300: '#EDF1F7',
  grey_400: '#C5CEE0',
  grey_500: '#8F9BB3',
  grey_600: '#636E89',
  greyMain: '#212121',
  // Primary
  primary: window?._env_?.primaryColor ? window?._env_?.primaryColor : '#004B55',
  // Green variations
  status_success: '#5CC689',
  // Orange variations
  secondary_300: '#FDCA68',
  secondary_400: '#FDAE35',
  secondary_500: '#FC9A03',
  secondary_600: '#F98C0D',
  secondary_700: '#CA6202',
  status_warning_light: '#FFFAE5',
  // Blue variations
  blue_400: '#5075CA',
  blue_600: '#123EA5',
  blueMain: '#001D4A',
  // Red variations
  status_error: '#E9263D',
  status_error_light: '#DF5162',
  // Yellow variations
  light_yellow: '#FFE885',
};

export const fontFamily = {
  main: `'Poppins', 'Lato', 'Helvetica', 'Arial', sans-serif`,
};

export const fontSize = {
  p1: '12px',
  p2: '10px',
  p3: '8px',
  title_xxxlarge: '42px',
  title_xxlarge: '32px',
  title_xlarge: '28px',
  title_large: '26px',
  title_medium: '22px',
  title_small: '18px',
  title_xsmall: '14px',
};

export const typography = {
  p1_regular: css`
    font-family: ${fontFamily.main};
    font-size: ${fontSize.p1};
    font-weight: normal;
    line-height: 1.5;
  `,
  p1_bold: css`
    font-family: ${fontFamily.main};
    font-size: ${fontSize.p1};
    font-weight: bold;
    line-height: 1.5;
  `,
  p1_italic: css`
    font-family: ${fontFamily.main};
    font-size: ${fontSize.p1};
    font-weight: normal;
    font-style: italic;
    line-height: 1.5;
  `,
  p2_regular: css`
    font-family: ${fontFamily.main};
    font-size: ${fontSize.p2};
    font-weight: normal;
    line-height: 1.5;
  `,
  p2_bold: css`
    font-family: ${fontFamily.main};
    font-size: ${fontSize.p2};
    font-weight: bold;
    line-height: 1.5;
  `,
  p2_italic: css`
    font-family: ${fontFamily.main};
    font-size: ${fontSize.p2};
    font-weight: normal;
    font-style: italic;
    line-height: 1.5;
  `,
  p3_regular: css`
    font-family: ${fontFamily.main};
    font-size: ${fontSize.p3};
    font-weight: normal;
    line-height: 1.5;
  `,
  p3_bold: css`
    font-family: ${fontFamily.main};
    font-size: ${fontSize.p3};
    font-weight: bold;
    line-height: 1.5;
  `,
  p3_italic: css`
    font-family: ${fontFamily.main};
    font-size: ${fontSize.p3};
    font-weight: normal;
    font-style: italic;
    line-height: 1.5;
  `,
  title_xxxlarge: css`
    font-family: ${fontFamily.main};
    font-size: ${fontSize.title_xxxlarge};
    font-weight: bold;
    line-height: 1.3;
  `,
  title_xxlarge: css`
    font-family: ${fontFamily.main};
    font-size: ${fontSize.title_xxlarge};
    font-weight: bold;
    line-height: 1.3;
  `,
  title_xlarge: css`
    font-family: ${fontFamily.main};
    font-size: ${fontSize.title_xlarge};
    font-weight: bold;
    line-height: 1.3;
  `,
  title_large: css`
    font-family: ${fontFamily.main};
    font-size: ${fontSize.title_large};
    font-weight: bold;
    line-height: 1.3;
  `,
  title_medium: css`
    font-family: ${fontFamily.main};
    font-size: ${fontSize.title_medium};
    font-weight: bold;
    line-height: 1.3;
  `,
  title_small: css`
    font-family: ${fontFamily.main};
    font-size: ${fontSize.title_small};
    font-weight: bold;
    line-height: 1.3;
  `,
  title_xsmall: css`
    font-family: ${fontFamily.main};
    font-size: ${fontSize.title_xsmall};
    font-weight: bold;
    line-height: 1.3;
  `,
};

export const frameHeight = {
  header: '80px',
  loader: '32px',
  buttonInnerSize: 27,
  tabHeader: '56px',
  tabHeaderMargin: getSpacing(2),
};
