import { LateralMenu } from 'components/Layout/LateralMenu/LateralMenu';
import { SidePanel } from 'components/SidePanel/SidePanel';

type Props = {
  isOpen: boolean;
  onAbort: () => void;
};

export const HumBurgerSidePanel = ({ isOpen, onAbort }: Props): JSX.Element => {
  return (
    <>
      <SidePanel
        title={''}
        isOpen={isOpen}
        onClose={onAbort}
        widthInPercent={12}
        anchor="left"
        isMobile={true}
      >
        <LateralMenu closeMenu={onAbort} />
      </SidePanel>
    </>
  );
};
