import { Button } from 'components/Button/Button';
import { TextInput } from 'components/Inputs/TextInput/TextInput';
import { HttpError } from 'services/api/types';
import { useIntlByKey } from 'services/i18n/hooks/useIntlByKey';
import { getSpacing } from 'stylesheet';

import { ButtonsContainer, FormRowContainer, SettingsFormContainer } from './SettingsForm.style';
import { SettingsFormKeys, SettingsInformation, useSettingsFormik } from './useSettingsFormik';

type SettingsFormProps = {
  initialFormValues: SettingsInformation;
  onSubmit: (settingsInfo: SettingsInformation) => Promise<void>;
  onSubmitError: (error: HttpError) => void;
  onAbort: () => void;
};

export const SettingsForm = ({
  initialFormValues,
  onSubmit,
  onSubmitError,
  onAbort,
}: SettingsFormProps): JSX.Element => {
  const settingsFormIntl = useIntlByKey('settings-form');

  const formik = useSettingsFormik(initialFormValues, onSubmit, onSubmitError);

  return (
    <SettingsFormContainer>
      <form onSubmit={formik.handleSubmit}>
        <FormRowContainer>
          <TextInput
            id={SettingsFormKeys.CONTACT_EMAIL}
            name={SettingsFormKeys.CONTACT_EMAIL}
            label={settingsFormIntl('form.contact-email.label')}
            placeholder={settingsFormIntl('form.contact-email.placeholder')}
            value={formik.values[SettingsFormKeys.CONTACT_EMAIL]}
            onChange={formik.handleChange}
            error={
              formik.touched[SettingsFormKeys.CONTACT_EMAIL] &&
              Boolean(formik.errors[SettingsFormKeys.CONTACT_EMAIL])
            }
            helperText={
              formik.touched[SettingsFormKeys.CONTACT_EMAIL] &&
              formik.errors[SettingsFormKeys.CONTACT_EMAIL]
            }
            required
          />
        </FormRowContainer>
        <FormRowContainer>
          <TextInput
            id={SettingsFormKeys.SUBSCRIPTION_REQUEST_EMAIL}
            name={SettingsFormKeys.SUBSCRIPTION_REQUEST_EMAIL}
            label={settingsFormIntl('form.subscription-request-email.label')}
            placeholder={settingsFormIntl('form.subscription-request-email.placeholder')}
            value={formik.values[SettingsFormKeys.SUBSCRIPTION_REQUEST_EMAIL]}
            onChange={formik.handleChange}
            error={
              formik.touched[SettingsFormKeys.SUBSCRIPTION_REQUEST_EMAIL] &&
              Boolean(formik.errors[SettingsFormKeys.SUBSCRIPTION_REQUEST_EMAIL])
            }
            helperText={
              formik.touched[SettingsFormKeys.SUBSCRIPTION_REQUEST_EMAIL] &&
              formik.errors[SettingsFormKeys.SUBSCRIPTION_REQUEST_EMAIL]
            }
            required
          />
        </FormRowContainer>

        <ButtonsContainer>
          <Button
            displayStyle="unfilledGrey"
            onClick={onAbort}
            margins={`0 ${getSpacing(1)} 0 0`}
            disabled={formik.isSubmitting}
            bigPaddings
          >
            {settingsFormIntl('form.cancel')}
          </Button>
          <Button
            displayStyle="filledOrange"
            type="submit"
            isLoading={formik.isSubmitting}
            bigPaddings
          >
            {settingsFormIntl('form.update')}
          </Button>
        </ButtonsContainer>
      </form>
    </SettingsFormContainer>
  );
};
