import { Button } from 'components/Button/Button';
import { Dialog } from 'components/Dialog/Dialog';
import { useDispatch } from 'react-redux';
import { TableKey } from 'redux/Tables/selectors';
import { setIsTableReloading } from 'redux/Tables/slice';
import { useIntlByKey } from 'services/i18n/hooks/useIntlByKey';

import { DialogBodyContainer } from './SuccessActionDialog.style';

type Props = {
  title: string;
  isOpen: boolean;
  tableToReload?: TableKey;
  onClose: () => void;
};

export const SuccessActionDialog = ({ isOpen, onClose, title, tableToReload }: Props) => {
  const successCreationDialogIntl = useIntlByKey('success-creation-dialog');
  const dispatch = useDispatch();

  const closeAndReloadData = () => {
    onClose();
    if (tableToReload) {
      dispatch(setIsTableReloading({ tableKey: tableToReload, isReloading: true }));
    }
  };

  return (
    <Dialog isOpen={isOpen} onClose={closeAndReloadData} title={title}>
      <DialogBodyContainer>
        <Button displayStyle="filledOrange" onClick={closeAndReloadData} bigPaddings>
          {successCreationDialogIntl('close')}
        </Button>
      </DialogBodyContainer>
    </Dialog>
  );
};
