import { TextField, TextFieldProps } from '@material-ui/core';

import { useSelectOrInputFieldStyle } from '../Input.style';
import { LabelContainer, TextFieldContainer } from './TextInput.style';

export type AdditionalTextInputProps = {
  inputMaxLength?: number;
  width?: number;
  withAutoComplete?: boolean;
};

export type TextInputProps = TextFieldProps & AdditionalTextInputProps;

export const TextInput = ({
  id,
  name,
  label,
  placeholder,
  value,
  onChange,
  error,
  helperText,
  inputMaxLength,
  multiline,
  rows,
  width,
  required,
  type,
  InputProps,
  inputProps,
  disabled,
  withAutoComplete = false,
}: TextInputProps): JSX.Element => {
  const emptyErrorHelperTextToPreserveSpacingWhenErrorIsDisplayed = ' ';
  const inputClasses = useSelectOrInputFieldStyle(width)();
  const widthProps = {
    style: width ? { width: `${width}px` } : undefined,
    fullWidth: width ? false : true,
  };

  return (
    <TextFieldContainer fullWidth={widthProps.fullWidth}>
      {label && (
        <LabelContainer htmlFor={id}>{label && label + (required ? '*' : '')}</LabelContainer>
      )}
      <TextField
        classes={inputClasses}
        id={id}
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        error={error}
        helperText={
          withAutoComplete
            ? ''
            : helperText === ''
            ? emptyErrorHelperTextToPreserveSpacingWhenErrorIsDisplayed
            : helperText
        }
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          ...inputProps,
          maxLength: inputMaxLength,
          'aria-label': name,
        }}
        InputProps={InputProps}
        variant="outlined"
        multiline={multiline}
        rows={rows}
        rowsMax={rows}
        {...widthProps}
        disabled={disabled}
      />
    </TextFieldContainer>
  );
};
