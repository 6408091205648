export const BUSINESS_ADMIN_ROLE_CODE = 'YLSTN_ADMIN_METIER';
export const CLIENT_ADMIN_ROLE_CODE = 'YLSTN_CLIENT_ADMIN';
export const CLIENT_ADMIN_TICKET_ROLE_CODE = 'YLSTN_CLIENT_ADMIN_TICKET';
export const CLIENT_CONTRIBUTOR_ROLE_CODE = 'YLSTN_CLIENT_CONTRIBUTOR';

const BACKOFFICE_ROLE_CODES = [BUSINESS_ADMIN_ROLE_CODE] as const;
const FRONTOFFICE_ROLE_CODES = [
  CLIENT_ADMIN_ROLE_CODE,
  CLIENT_CONTRIBUTOR_ROLE_CODE,
  CLIENT_ADMIN_TICKET_ROLE_CODE,
] as const;

// If you add a new role here, please also add it to the list of roles in the src/DTO/Invitation/CreateInvitationRequestBodyDTO.php
export const ALLOWED_ROLE_CODES_FOR_BACKOFFICE_INVITATION = [
  CLIENT_ADMIN_ROLE_CODE,
  CLIENT_ADMIN_TICKET_ROLE_CODE,
] as ApiRoleCode[];
export const ALLOWED_ROLE_CODES_FOR_FRONTOFFICE_INVITATION = [
  CLIENT_CONTRIBUTOR_ROLE_CODE,
  CLIENT_ADMIN_ROLE_CODE,
  CLIENT_ADMIN_TICKET_ROLE_CODE,
] as ApiRoleCode[];

export const ALLOWED_ROLE_CODES_FOR_FRONTOFFICE_USER_EDITION = [
  CLIENT_CONTRIBUTOR_ROLE_CODE,
  CLIENT_ADMIN_ROLE_CODE,
  CLIENT_ADMIN_TICKET_ROLE_CODE,
] as ApiRoleCode[];

export type BackOfficeApiRoleCode = typeof BACKOFFICE_ROLE_CODES[number];
export type FrontOfficeApiRoleCode = typeof FRONTOFFICE_ROLE_CODES[number];
export type ApiRoleCode = FrontOfficeApiRoleCode | BackOfficeApiRoleCode;

export type BaseApiRole = {
  code: ApiRoleCode;
  label: string;
};
