export const PLATFORM_LEGAL_CORPORATION_ID =
  window?._env_?.legalCorporation?.id ?? process.env.REACT_APP_PLATFORM_LEGAL_CORPORATION_ID ?? '';

export const PLATFORM_LEGAL_CORPORATION_LABEL =
  window?._env_?.legalCorporation?.label ??
  process.env.REACT_APP_PLATFORM_LEGAL_CORPORATION_LABEL ??
  '';

export const APPINSIGHT_KEY = process.env.REACT_APP_APPINSIGHT_KEY ?? '';

export const PLATFORM_LEGAL_CORPORATION_WEB_SITE =
  window?._env_?.legalCorporation?.corporateWebsiteUrl ??
  process.env.REACT_APP_PLATFORM_LEGAL_CORPORATION_WEB_SITE ??
  '';

export const PLATFORM_SITES_MAP_CENTER_LATITUDE =
  window?._env_?.sitesMap?.centerLatitude ??
  process.env.REACT_APP_PLATFORM_SITES_MAP_CENTER_LATITUDE ??
  '';

export const PLATFORM_SITES_MAP_CENTER_LONGITUDE =
  window?._env_?.sitesMap?.centerLongitude ??
  process.env.REACT_APP_PLATFORM_SITES_MAP_CENTER_LONGITUDE ??
  '';

export const CUSTOMER_AGREEMENT_VERSION = parseInt(
  window?._env_?.customerAgreementVersion ??
    process.env.REACT_APP_CUSTOMER_AGREEMENT_VERSION ??
    '0',
);

export const CONTACT_MAIL_ADDRESS = process.env.REACT_APP_CONTACT_MAIL_ADDRESS ?? '';
export const GDPR_MAIL_ADDRESS = 'dataprivacy@colas.com';

export const HTTP_BAD_REQUEST_STATUS_CODE = 400;
export const HTTP_NOT_FOUND_STATUS_CODE = 404;
export const HTTP_CONFLICT_STATUS_CODE = 409;
export const GOOGLE_ANALYTICS_TRACKIND_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRAKING_ID ?? '';

export const DATADOG_CLIENT_TOKEN = process.env.REACT_APP_DATADOG_CLIENT_TOKEN ?? '';
export const DATADOG_SITE = process.env.REACT_APP_DATADOG_SITE ?? '';
export const DATADOG_ENV = process.env.REACT_APP_DATADOG_ENV ?? '';
export const DATADOG_PROJECT_SERVICE = process.env.REACT_APP_DATADOG_PROJECT_SERVICE ?? '';

export const AZURE_AD_B2C_REDIRECTION_URI =
  window?._env_?.azureAdB2C?.redirectionUri ?? process.env.REACT_APP_AUTH_AZURE_REDIRECT_URI;
export const AZURE_AD_B2C_CLIENT_ID =
  window?._env_?.azureAdB2C?.clientId ?? process.env.REACT_APP_AUTH_AZURE_CLIENT_ID;
export const AZURE_SIGN_IN_SIGN_UP_POLICY =
  window?._env_?.azureAdB2C?.signInSignUpPolicy ??
  process.env.REACT_APP_AUTH_AZURE_SIGN_IN_SIGN_UP_POLICY;
export const AZURE_TENANT_NAME =
  window?._env_?.azureAdB2C?.tenantName ?? process.env.REACT_APP_AUTH_AZURE_TENANT_NAME;
export const AZURE_BACKEND_CLIENT_ID =
  window?._env_?.azureAdB2C?.backendClientId ?? process.env.REACT_APP_AUTH_AZURE_BACKEND_CLIENT_ID;

export const PLATFORM_LEGAL_CORPORATION_LOCATION = window?._env_?.legalCorporation?.location ?? '';

export const PLATFORM_LEGAL_CORPORATION_DISTANCE_UOM = window?._env_?.distanceUOM ?? 'mile';

export const PLATFORM_LANGUAGE = window?._env_?.language ?? 'en';

export const LEGAl_CORPORATION_BRAND_LOGOS_BY_ID: any = {
  '110': [
    {
      image: 'HeaderBrandLogo.png',
      url: 'https://barrettindustries.com',
    },
    {
      image: 'BarrettPavingLogo.png',
      url: 'https://barrettpaving.com',
    },
    {
      image: 'MidlandLogo.png',
      url: 'https://midlandasphalt.com',
    },
    {
      image: 'StrawserLogo.png',
      url: 'https://strawserconstruction.com',
    },
    {
      image: 'TerryAsphaltLogo.png',
      url: 'https://terryasphalt.com',
    },
    {
      image: 'UpstoneLogo.png ',
      url: 'https://upstonematerials.com',
    },
  ],
  '210': [
    {
      image: 'HeaderBrandLogo.png',
      url: 'https://sully-miller.com',
    },
    {
      image: 'BlueDiamondLogo.png',
      url: 'https://sully-miller.com/blue-diamond/',
    },
    {
      image: 'UnitedRockLogo.png',
      url: 'https://sully-miller.com/united-rock/',
    },
  ],
  '505': [
    {
      image: 'HeaderBrandLogo.png',
      url: 'https://www.colascanada.ca',
    },
    {
      image: 'CRB-Logo.png',
      url: 'https://crbi.ca',
    },
    {
      image: 'NPA-Logo.png',
      url: 'https://www.npaltd.ca',
    },
    {
      image: 'SGE-Logo.png',
      url: 'https://standardgeneraledmonton.ca',
    },
    {
      image: 'SGC-Logo.png',
      url: 'https://standardgeneraledmonton.ca',
    },
    {
      image: 'Terus-Logo.png',
      url: 'https://terusconstruction.ca',
    },
  ],
};
