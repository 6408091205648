import { SettingsIcon } from 'assets/icons/SettingsIcon';
import { SettingsForm } from 'components/Form/Settings/SettingsForm';
import {
  DEFAULT_VALUES,
  SettingsFormKeys,
  SettingsInformation,
} from 'components/Form/Settings/useSettingsFormik';
import { SidePanel } from 'components/SidePanel/SidePanel';
import { useDispatch, useSelector } from 'react-redux';
import { setIsError } from 'redux/ApiResponseStatusGlobalDisplay/slice';
import { getLegalCorporationConfig } from 'redux/LegalCorporation/selectors';
import { getUserMetadataId } from 'redux/User/selectors';
import { getClientCompanySingleLegalCorporationId } from 'services/api/common';
import { updateSettings } from 'services/api/legalCorporationConfig/client';
import { HttpError } from 'services/api/types';
import { PLATFORM_REVISED_LEGAL_CORPORATION_ID } from 'services/authentication/msalConfig';
import { useIntlByKey } from 'services/i18n/hooks/useIntlByKey';

type Props = {
  isOpen: boolean;
  onAbort: () => void;
  onSettingsUpdateSuccess: () => void;
};

export const SettingsSidePanel = ({
  isOpen,
  onAbort,
  onSettingsUpdateSuccess,
}: Props): JSX.Element => {
  const userMetadataId = useSelector(getUserMetadataId);
  const legalCorporationConfig = useSelector(getLegalCorporationConfig);
  const SettingsIntl = useIntlByKey('settings-form');
  const dispatch = useDispatch();

  const onSubmit = async (settingsInfo: SettingsInformation) => {
    const legalCorporationId = getClientCompanySingleLegalCorporationId(
      PLATFORM_REVISED_LEGAL_CORPORATION_ID,
    );
    if (legalCorporationId && userMetadataId) {
      await updateSettings({
        ...settingsInfo,
        legalCorporationId: legalCorporationId,
        updatedBy: userMetadataId,
      });
      onSettingsUpdateSuccess();
    }
  };

  const onSubmitError = (httpError: HttpError) => {
    dispatch(setIsError(true));
  };

  return (
    <>
      <SidePanel
        title={SettingsIntl('title')}
        titleIcon={<SettingsIcon />}
        isOpen={isOpen}
        onClose={onAbort}
      >
        <SettingsForm
          onSubmit={onSubmit}
          onSubmitError={onSubmitError}
          onAbort={onAbort}
          initialFormValues={{
            ...DEFAULT_VALUES,
            [SettingsFormKeys.CONTACT_EMAIL]: legalCorporationConfig?.contactEmail ?? '',
            [SettingsFormKeys.SUBSCRIPTION_REQUEST_EMAIL]:
              legalCorporationConfig?.subscriptionRequestEmail ?? '',
          }}
        />
      </SidePanel>
    </>
  );
};
