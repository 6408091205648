import { useFormik } from 'formik';
import { HttpError } from 'services/api/types';
import { useIntlByKey } from 'services/i18n/hooks/useIntlByKey';
import { logger } from 'services/logs/logger';
import * as yup from 'yup';

export enum SettingsFormKeys {
  CONTACT_EMAIL = 'contactEmail',
  SUBSCRIPTION_REQUEST_EMAIL = 'subscriptionRequestEmail',
}

export type SettingsInformation = {
  [SettingsFormKeys.CONTACT_EMAIL]: string;
  [SettingsFormKeys.SUBSCRIPTION_REQUEST_EMAIL]: string;
};

export const DEFAULT_VALUES = {
  [SettingsFormKeys.CONTACT_EMAIL]: '',
  [SettingsFormKeys.SUBSCRIPTION_REQUEST_EMAIL]: '',
};

export const useSettingsFormik = (
  initialFormValues: SettingsInformation,
  onSubmit: (settingsInfo: SettingsInformation) => Promise<void>,
  onSubmitError: (error: HttpError) => void,
) => {
  const settingsFormIntl = useIntlByKey('settings-form');

  const requiredFieldMessage = settingsFormIntl('form.required-field');
  const invalidFieldMessage = settingsFormIntl('form.invalid-field');

  const validationSchema = yup.object({
    [SettingsFormKeys.CONTACT_EMAIL]: yup
      .string()
      .email(invalidFieldMessage)
      .required(requiredFieldMessage),

    [SettingsFormKeys.SUBSCRIPTION_REQUEST_EMAIL]: yup
      .string()
      .email(invalidFieldMessage)
      .required(requiredFieldMessage),
  });

  return useFormik({
    initialValues: initialFormValues,
    validationSchema,
    onSubmit: async (values: SettingsInformation) => {
      try {
        await onSubmit(values);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        logger.logError('Error submitting formik form', error);
        onSubmitError(error.response as HttpError);
      }
    },
  });
};
