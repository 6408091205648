import { Button } from 'components/Button/Button';
import { TextInput } from 'components/Inputs/TextInput/TextInput';
import { HttpError } from 'services/api/types';
import { useIntlByKey } from 'services/i18n/hooks/useIntlByKey';
import { getSpacing } from 'stylesheet';

import { ButtonsContainer, FormRowContainer, MyProfileFormContainer } from './MyProfileForm.style';
import {
  ProfileInformation,
  ProfileInformationFormKeys,
  useMyProfileFormik,
} from './useMyProfileFormik';

type MyProfileFormProps = {
  initialFormValues: ProfileInformation;
  onSubmit: (profileInfo: ProfileInformation) => Promise<void>;
  onSubmitError: (error: HttpError) => void;
  onAbort: () => void;
};

export const MyProfileForm = ({
  initialFormValues,
  onSubmit,
  onSubmitError,
  onAbort,
}: MyProfileFormProps): JSX.Element => {
  const myProfileFormIntl = useIntlByKey('my-profile-form');

  const formik = useMyProfileFormik(initialFormValues, onSubmit, onSubmitError);

  return (
    <MyProfileFormContainer>
      <form onSubmit={formik.handleSubmit}>
        <FormRowContainer>
          <TextInput
            id={ProfileInformationFormKeys.USER_PHONE_NUMBER}
            name={ProfileInformationFormKeys.USER_PHONE_NUMBER}
            label={myProfileFormIntl('form.phone-number.label')}
            placeholder={myProfileFormIntl('form.phone-number.placeholder')}
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
            helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
            required
          />
        </FormRowContainer>

        <ButtonsContainer>
          <Button
            displayStyle="unfilledGrey"
            onClick={onAbort}
            margins={`0 ${getSpacing(1)} 0 0`}
            disabled={formik.isSubmitting}
            bigPaddings
          >
            {myProfileFormIntl('form.cancel')}
          </Button>
          <Button
            displayStyle="filledOrange"
            type="submit"
            isLoading={formik.isSubmitting}
            bigPaddings
          >
            {myProfileFormIntl('form.update-profile')}
          </Button>
        </ButtonsContainer>
      </form>
    </MyProfileFormContainer>
  );
};
