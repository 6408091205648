import { ApiResponseStatusGlobalDisplay } from 'components/ApiResponseStatusGlobalDisplay/ApiResponseStatusGlobalDisplay';
import { FunctionComponent } from 'react';

import { Header } from './Header/Header';
import { LateralMenu } from './LateralMenu/LateralMenu';
import { BodyContainer, LayoutContainer, MainContainer } from './Layout.style';
import { useCheckMobileScreen } from './useCheckMobileScreen';

export const Layout: FunctionComponent = (props) => {
  const ismobile = useCheckMobileScreen();
  return (
    <LayoutContainer>
      {!ismobile ? <LateralMenu /> : ''}
      <BodyContainer>
        <Header />
        <MainContainer>{props.children}</MainContainer>
        <ApiResponseStatusGlobalDisplay />
      </BodyContainer>
    </LayoutContainer>
  );
};
