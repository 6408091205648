import styled from 'styled-components';
import { colorPalette, device, getSpacing } from 'stylesheet';

export const MessageContainer = styled.div`
  @medis ${device.tabletMax} {
    width: 50%;
  }
`;

export const MessagePart = styled.div`
  text-align: justify;
  margin-top: ${getSpacing(2)};
  @media ${device.mobileMax} {
    width: 50%;
    margin-left: 250px;
  }
`;

export const LinkPart = styled.div`
  text-align: center;
  margin-top: ${getSpacing(4)};
  @media ${device.mobileMax} {
    width: 50%;
    margin-left: 250px;
  }
`;

export const Signature = styled.div`
  margin-top: ${getSpacing(4)};
  color: ${colorPalette.primary};
  font-weight: bold;
  text-align: right;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  @media ${device.mobileMax} {
    margin-bottom: 200px;
  }
`;

export const HelpSidePanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: ${getSpacing(4)};
`;
