import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BaseApiLegalCorporationConfig } from 'services/api/legalCorporationConfig/types';

export type LegalCorporationState = {
  legalCorporationConfig: BaseApiLegalCorporationConfig | null;
};

const initialState: LegalCorporationState = {
  legalCorporationConfig: null,
};

const legalCorporationSlice = createSlice({
  name: 'legalCorporation',
  initialState,
  reducers: {
    storeLegalCorporationConfig: (
      state,
      action: PayloadAction<BaseApiLegalCorporationConfig | null>,
    ) => {
      state.legalCorporationConfig = action.payload;
    },
  },
});

export const { storeLegalCorporationConfig } = legalCorporationSlice.actions;
export const legalCorporationReducer = legalCorporationSlice.reducer;
