import styled from 'styled-components';
import { getSpacing } from 'stylesheet';

export const TextFieldContainer = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  ${(props) => (props.fullWidth ? 'width : 100%' : '')}
`;
export const LabelContainer = styled.label`
  margin-bottom: ${getSpacing(1)};
`;
