import { HelpAndContactIcon } from 'assets/icons/HelpAndContactIcon';
import { Button } from 'components/Button/Button';
import { SidePanel } from 'components/SidePanel/SidePanel';
import { useSelector } from 'react-redux';
import { getLegalCorporationConfig } from 'redux/LegalCorporation/selectors';
import { analytics } from 'services/analytics/analytics';
import {
  GOOGLE_ANALYTICS_ACTIONS,
  GOOGLE_ANALYTICS_CATEGORIES,
  GOOGLE_ANALYTICS_LABELS,
} from 'services/analytics/config';
import { CONTACT_MAIL_ADDRESS, PLATFORM_LEGAL_CORPORATION_LABEL } from 'services/api/constants';
import { useIntlByKey } from 'services/i18n/hooks/useIntlByKey';

import {
  ButtonContainer,
  HelpSidePanelContainer,
  LinkPart,
  MessageContainer,
  MessagePart,
  Signature,
} from './HelpAndContactSidePanel.style';

interface Props {
  onClose: () => void;
  isOpen: boolean;
}

export const HelpAndContactSidePanel = ({ onClose, isOpen }: Props) => {
  const helpAndContactSidePanelIntl = useIntlByKey('help-and-contact-side-panel');
  const legalCorporationConfig = useSelector(getLegalCorporationConfig);

  const sendGoogleAnalyticsHelpAndContactEmailClick = () => {
    analytics.event(
      GOOGLE_ANALYTICS_ACTIONS.HELP_AND_CONTACT_EMAIL_CLICK,
      GOOGLE_ANALYTICS_LABELS.CLICK,
      GOOGLE_ANALYTICS_CATEGORIES.HELP_AND_CONTACT,
    );
  };

  return (
    <SidePanel
      title={helpAndContactSidePanelIntl('title')}
      titleIcon={<HelpAndContactIcon />}
      isOpen={isOpen}
      onClose={onClose}
      widthInPercent={10}
    >
      <HelpSidePanelContainer>
        <MessageContainer>
          <MessagePart>
            {helpAndContactSidePanelIntl('in-case-of-problems-contact-technical-team')}
          </MessagePart>
          <LinkPart>
            <a
              href={`mailto:${legalCorporationConfig?.contactEmail ?? CONTACT_MAIL_ADDRESS ?? ''}`}
              target="_blank"
              rel="noreferrer"
              onClick={sendGoogleAnalyticsHelpAndContactEmailClick}
            >
              {legalCorporationConfig?.contactEmail ?? CONTACT_MAIL_ADDRESS ?? ''}
            </a>
          </LinkPart>
          <MessagePart>
            {helpAndContactSidePanelIntl('this-service-is-made-to-answer-user-needs')}
          </MessagePart>
          <MessagePart>{helpAndContactSidePanelIntl('thanks-for-your-collaboration')}</MessagePart>
          <Signature>
            {helpAndContactSidePanelIntl('signature', {
              legalCorporationName: PLATFORM_LEGAL_CORPORATION_LABEL,
            })}
          </Signature>
        </MessageContainer>
        <ButtonContainer>
          <Button displayStyle={'filledOrange'} onClick={onClose}>
            {helpAndContactSidePanelIntl('close-button')}
          </Button>
        </ButtonContainer>
      </HelpSidePanelContainer>
    </SidePanel>
  );
};
