import { TableFilters } from '../filtersKeys';

export enum ClientCompaniesFiltersKeys {
  TYPE = 'isInternal',
  ID_MDM = 'id',
  CLIENT_COMPANY_NAME = 'name',
  SIRET = 'siret',
  OPEN_DATE = 'clientCompanyLegalCorporations.openDate',
  PORTAL_ACCESS = 'clientCompanyLegalCorporations.portalAccess',
}

export const CLIENT_COMPANIES_FILTERS_INITIAL_VALUES: TableFilters = {
  [ClientCompaniesFiltersKeys.TYPE]: '',
  [ClientCompaniesFiltersKeys.ID_MDM]: '',
  [ClientCompaniesFiltersKeys.CLIENT_COMPANY_NAME]: '',
  [ClientCompaniesFiltersKeys.SIRET]: '',
  [ClientCompaniesFiltersKeys.OPEN_DATE]: { before: null, after: null },
  [ClientCompaniesFiltersKeys.PORTAL_ACCESS]: '',
};
