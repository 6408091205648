import styled from 'styled-components';
import { getSpacing } from 'stylesheet';

export const FormRowContainer = styled.div`
  display: flex;
  > * {
    flex: 0.5;
    &:first-child {
      margin-right: ${getSpacing(3)};
    }
  }
  margin-bottom: ${getSpacing(1)};
`;

export const ButtonsContainer = styled.div`
  margin-top: ${getSpacing(5)};
  display: flex;
  justify-content: flex-end;
`;
export const MyProfileFormContainer = styled.div`
  margin-top: ${getSpacing(5)};
`;
