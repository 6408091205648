import { ProfileIcon } from 'assets/icons/ProfileIcon';
import { MyProfileForm } from 'components/Form/MyProfile/MyProfileForm';
import {
  DEFAULT_VALUES,
  ProfileInformation,
  ProfileInformationFormKeys,
} from 'components/Form/MyProfile/useMyProfileFormik';
import { SidePanel } from 'components/SidePanel/SidePanel';
import { useDispatch, useSelector } from 'react-redux';
import { setIsError } from 'redux/ApiResponseStatusGlobalDisplay/slice';
import { getUserMetadataId, getUserPhoneNumber } from 'redux/User/selectors';
import { updateProfile } from 'services/api/myProfile/client';
import { HttpError } from 'services/api/types';
import { useIntlByKey } from 'services/i18n/hooks/useIntlByKey';

type Props = {
  isOpen: boolean;
  onAbort: () => void;
  onProfileUpdateSuccess: () => void;
};

export const MyProfileSidePanel = ({
  isOpen,
  onAbort,
  onProfileUpdateSuccess,
}: Props): JSX.Element => {
  const MyProfileIntl = useIntlByKey('my-profile-form');
  const dispatch = useDispatch();
  const userMetadataId = useSelector(getUserMetadataId);
  const userphoneNumber = useSelector(getUserPhoneNumber);

  const onSubmit = async (profileInfo: ProfileInformation) => {
    if (userMetadataId) {
      await updateProfile({ ...profileInfo, userId: userMetadataId });
      onProfileUpdateSuccess();
    }
  };

  const onSubmitError = (httpError: HttpError) => {
    dispatch(setIsError(true));
  };

  return (
    <>
      <SidePanel
        title={MyProfileIntl('title')}
        titleIcon={<ProfileIcon />}
        isOpen={isOpen}
        onClose={onAbort}
      >
        <MyProfileForm
          onSubmit={onSubmit}
          onSubmitError={onSubmitError}
          onAbort={onAbort}
          initialFormValues={{
            ...DEFAULT_VALUES,
            [ProfileInformationFormKeys.USER_PHONE_NUMBER]: userphoneNumber ? userphoneNumber : '',
          }}
        />
      </SidePanel>
    </>
  );
};
