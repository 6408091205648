import { BaseMenuPageConfig, LateralMenuLinkConfig } from 'hooks/useMenuPageConfigs';
import { analytics } from 'services/analytics/analytics';

import { LateralMenuLinkContainer, LateralMenuLinkLabel } from './LateralMenuLink.style';

type Props = BaseMenuPageConfig & LateralMenuLinkConfig;

export const LateralMenuLink = ({
  path,
  search,
  title,
  icon: Icon,
  disabledOnActive,
  googleAnalyticsTagConfig,
  closeMenu,
}: Props): JSX.Element => {
  const onLateralMenuClick = () => {
    if (closeMenu) {
      closeMenu();
    }
    analytics.event(
      googleAnalyticsTagConfig!.action,
      googleAnalyticsTagConfig!.label,
      googleAnalyticsTagConfig!.category,
    );
  };

  const pathObject = {
    pathname: path,
    search: search ?? '',
  };

  return (
    <LateralMenuLinkContainer
      to={pathObject}
      activeClassName={`is-active${disabledOnActive ? ' disabled' : ''}`}
      onClick={onLateralMenuClick}
    >
      <Icon widthInPx={16} heightInPx={20} isLateralMenuIcon={true} />
      <LateralMenuLinkLabel>{title}</LateralMenuLinkLabel>
    </LateralMenuLinkContainer>
  );
};
