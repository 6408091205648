import { PLATFORM_REVISED_LEGAL_CORPORATION_ID } from 'services/authentication/msalConfig';

import { apiClient } from '../apiClient';
import { getClientCompanySingleLegalCorporationId } from '../common';
import { ApiActionResponse, ApiPageableResponse } from '../types';
import { BaseApiLegalCorporationConfig, UpdateSettingsRequestBody } from './types';

export const fetchLegalCorporationConfig = () => {
  const legalCorporationID = getClientCompanySingleLegalCorporationId(
    PLATFORM_REVISED_LEGAL_CORPORATION_ID,
  );
  return apiClient.request<ApiPageableResponse<BaseApiLegalCorporationConfig>>(
    'GET',
    `/legal-corporation-configs?legalCorporationId=${legalCorporationID}`,
  );
};

export const updateSettings = (data: UpdateSettingsRequestBody) => {
  return apiClient.request<ApiActionResponse>('POST', `/update-settings`, data);
};
