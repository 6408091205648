import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';
import { getSpacing } from 'stylesheet';

export const SelectFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const LabelContainer = styled.label`
  margin-bottom: ${getSpacing(1)};
`;

export const SelectInputIconContainer = styled.div`
  display: flex;
  margin-left: 10px;
`;

export const useMenuStyle = (width?: number) =>
  makeStyles({
    menu: {
      height: '300px',
      width: width ? `${width}px` : '100%',
    },
  });

export const SelectChoiceItemOverflowContent = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const InformationIconButtonStyle = { padding: 0, paddingLeft: getSpacing(1) };
