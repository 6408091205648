import { useFormik } from 'formik';
import { HttpError } from 'services/api/types';
import { useIntlByKey } from 'services/i18n/hooks/useIntlByKey';
import { logger } from 'services/logs/logger';
import * as yup from 'yup';

import { FRENCH_PHONE_NUMBER_REGEX } from '../validationSchema';

export enum ProfileInformationFormKeys {
  USER_PHONE_NUMBER = 'phoneNumber',
}

export type ProfileInformation = {
  [ProfileInformationFormKeys.USER_PHONE_NUMBER]: string;
};

export const DEFAULT_VALUES = {
  [ProfileInformationFormKeys.USER_PHONE_NUMBER]: '',
};

export const useMyProfileFormik = (
  initialFormValues: ProfileInformation,
  onSubmit: (profileInfo: ProfileInformation) => Promise<void>,
  onSubmitError: (error: HttpError) => void,
) => {
  const myProfileFormIntl = useIntlByKey('my-profile-form');

  const requiredFieldMessage = myProfileFormIntl('form.required-field');
  const invalidFieldMessage = myProfileFormIntl('form.invalid-field');

  const validationSchema = yup.object({
    [ProfileInformationFormKeys.USER_PHONE_NUMBER]: yup
      .string()
      .matches(FRENCH_PHONE_NUMBER_REGEX, invalidFieldMessage)
      .required(requiredFieldMessage),
  });

  return useFormik({
    initialValues: initialFormValues,
    validationSchema,
    onSubmit: async (values: ProfileInformation) => {
      try {
        await onSubmit(values);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        logger.logError('Error submitting formik form', error);
        onSubmitError(error.response as HttpError);
      }
    },
  });
};
