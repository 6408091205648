import { RootState } from 'redux/store';
import { CUSTOMER_AGREEMENT_VERSION } from 'services/api/constants';
import { InvoiceNotificationSetting } from 'services/api/invoiceNotification/types';
import { ApiPermissionInList } from 'services/api/permissions/types';
import {
  ApiRoleCode,
  BUSINESS_ADMIN_ROLE_CODE,
  CLIENT_ADMIN_ROLE_CODE,
  CLIENT_ADMIN_TICKET_ROLE_CODE,
  CLIENT_CONTRIBUTOR_ROLE_CODE,
} from 'services/api/roles/types';
import { findSelectedPermission } from 'services/utils/permissions';

export const getUserPermissions = (rootState: RootState): ApiPermissionInList[] =>
  rootState.user.permissions;

export enum UserApplication {
  FRONT_OFFICE = 'frontOffice',
  BACK_OFFICE = 'backOffice',
  NO_ACCESS = 'noAccess',
}

export const getUserApplication = (rootState: RootState): UserApplication => {
  if (!hasUserAccessToApplication(rootState)) {
    return UserApplication.NO_ACCESS;
  }

  if (isBackOfficeUser(rootState)) {
    return UserApplication.BACK_OFFICE;
  }

  return UserApplication.FRONT_OFFICE;
};

export const shouldSignLatestTermsOfAgreement = (rootState: RootState): boolean => {
  return isFrontOfficeUser(rootState) && !hasSignedLatestCustomerAgreement(rootState);
};

const hasSignedLatestCustomerAgreement = (rootState: RootState): boolean => {
  const permission = getSelectedPermission(rootState);

  if (permission) {
    return permission.customerAgreements.some(
      (customerAgreement) => customerAgreement.version === CUSTOMER_AGREEMENT_VERSION,
    );
  }

  return false;
};

export const hasUserAccessToApplication = (rootState: RootState): boolean => {
  return getUserPermissions(rootState).length > 0;
};

export const isBackOfficeUser = (rootState: RootState): boolean => {
  const userRoleCode = getUserRoleCode(rootState);
  if (!userRoleCode) {
    return false;
  }
  return [BUSINESS_ADMIN_ROLE_CODE].includes(userRoleCode);
};

export const isTicketUser = (rootState: RootState): boolean => {
  const userRoleCode = getUserRoleCode(rootState);
  if (!userRoleCode) {
    return false;
  }
  return [CLIENT_ADMIN_TICKET_ROLE_CODE].includes(userRoleCode);
};

export const isFrontOfficeUser = (rootState: RootState): boolean => {
  const userRoleCode = getUserRoleCode(rootState);
  if (userRoleCode === undefined) {
    return false;
  }
  return [
    CLIENT_ADMIN_ROLE_CODE,
    CLIENT_ADMIN_TICKET_ROLE_CODE,
    CLIENT_CONTRIBUTOR_ROLE_CODE,
  ].includes(userRoleCode);
};

export const getUserRoleLabel = (rootState: RootState): string | undefined => {
  return getSelectedPermission(rootState)?.role.label;
};

export const getUserRoleCode = (rootState: RootState): ApiRoleCode | undefined => {
  return getSelectedPermission(rootState)?.role.code;
};

export const getUserClientCompanyId = (rootState: RootState): number | undefined => {
  return getSelectedPermission(rootState)?.clientCompany?.id;
};

export const getClientCompanyLegalCorporationId = (rootState: RootState): string => {
  return rootState.user.legalCorporationId;
};

export const getSelectedPermissionId = (rootState: RootState): string => {
  return rootState.user.selectedPermissionId;
};

export const getUserClientCompanyName = (rootState: RootState): string | undefined => {
  return getSelectedPermission(rootState)?.clientCompany?.name;
};

export const getUserClientCompanyIsInternal = (rootState: RootState): boolean | undefined => {
  return getSelectedPermission(rootState)?.clientCompany?.isInternal;
};

export const getSelectedPermission = (rootState: RootState): ApiPermissionInList | null => {
  const permissions = getUserPermissions(rootState);

  return findSelectedPermission(permissions, rootState.user.selectedPermissionId);
};

export const getUserMetadataId = (rootState: RootState): string | undefined => {
  return getSelectedPermission(rootState)?.userMetadata.id;
};

export const getUserPhoneNumber = (rootState: RootState): string | null | undefined => {
  return getSelectedPermission(rootState)?.userMetadata.phoneNumber;
};

export const getInvoiceNotificationSetting = (
  rootState: RootState,
): InvoiceNotificationSetting | undefined => {
  return getSelectedPermission(rootState)?.invoiceNotificationSetting;
};

export const getNumberOfUnreadMessages = (rootState: RootState): number => {
  return rootState.user.numberOfUnreadMessages;
};
