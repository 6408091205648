import styled from 'styled-components';
import { colorPalette, device, frameHeight, getSpacing } from 'stylesheet';

export const LayoutContainer = styled.div`
  display: flex;
  height: 100%;
`;

export const BodyContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${getSpacing(3)};
  background-color: ${colorPalette.backgroundMain};

  @media ${device.laptopMin} {
    height: 100%;
    min-height: calc(100% - ${frameHeight.header});
  }
`;
