import { TableFilters } from '../filtersKeys';

export enum UserClientCompaniesFiltersKeys {
  TYPE = 'clientCompany.isInternal',
  ID_MDM = 'clientCompany.id',
  CLIENT_COMPANY_NAME = 'clientCompany.name',
  SIRET = 'clientCompany.siret',
  OPEN_DATE = 'clientCompany.clientCompanyLegalCorporations.openDate',
  PORTAL_ACCESS = 'clientCompany.clientCompanyLegalCorporations.portalAccess',
}

export const USER_CLIENT_COMPANIES_FILTERS_INITIAL_VALUES: TableFilters = {
  [UserClientCompaniesFiltersKeys.TYPE]: '',
  [UserClientCompaniesFiltersKeys.ID_MDM]: '',
  [UserClientCompaniesFiltersKeys.CLIENT_COMPANY_NAME]: '',
  [UserClientCompaniesFiltersKeys.SIRET]: '',
  [UserClientCompaniesFiltersKeys.OPEN_DATE]: { before: null, after: null },
  [UserClientCompaniesFiltersKeys.PORTAL_ACCESS]: '',
};
