import { HelpAndContactIcon } from 'assets/icons/HelpAndContactIcon';
import { HelpAndContactSidePanel } from 'components/HelpAndContactSidePanel/HelpAndContactSidePanel';
import { useMenuPageConfigs } from 'hooks/useMenuPageConfigs';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserApplication, UserApplication } from 'redux/User/selectors';
import { useAuthorizationService } from 'redux/User/useAuthorizationService';
import { analytics } from 'services/analytics/analytics';
import {
  GOOGLE_ANALYTICS_ACTIONS,
  GOOGLE_ANALYTICS_CATEGORIES,
  GOOGLE_ANALYTICS_LABELS,
} from 'services/analytics/config';
import { useIntlByKey } from 'services/i18n/hooks/useIntlByKey';

import {
  LateralMenuBottomContainer,
  LateralMenuContainer,
  LateralMenuTopContainer,
} from './LateralMenu.style';
import { LateralMenuButton, LateralMenuButtonProps } from './LateralMenuButton/LateralMenuButton';
import { LateralMenuLink } from './LateralMenuLink/LateralMenuLink';

type LaterlMenuProps = {
  closeMenu?: () => void;
};

export const LateralMenu = ({ closeMenu }: LaterlMenuProps): JSX.Element => {
  const menuIntl = useIntlByKey('menu');
  const userApplication = useSelector(getUserApplication);
  const menuPageConfigs = useMenuPageConfigs();

  const [isHelpAndContactSidePanelOpened, setIsHelpAndContactSidePanelOpened] = useState(false);

  const onHelpAndContactIconClick = () => {
    setIsHelpAndContactSidePanelOpened(true);
    analytics.event(
      GOOGLE_ANALYTICS_ACTIONS.HELP_AND_CONTACT_PANEL_VIEW,
      GOOGLE_ANALYTICS_LABELS.PAGE_VIEW,
      GOOGLE_ANALYTICS_CATEGORIES.HELP_AND_CONTACT,
    );
  };

  const menuButtons: LateralMenuButtonProps[] = [
    {
      label: menuIntl('help-and-contact'),
      icon: <HelpAndContactIcon widthInPx={20} heightInPx={20} />,
      onButtonClick: () => onHelpAndContactIconClick(),
    },
  ];

  const byApplicationsAndByRoles = useAuthorizationService();

  return (
    <LateralMenuContainer>
      <LateralMenuTopContainer>
        {userApplication !== UserApplication.NO_ACCESS &&
          menuPageConfigs
            .filter(byApplicationsAndByRoles)
            .map((menuPageConfig) => (
              <LateralMenuLink
                key={menuPageConfig.lateralMenuLinkConfig.title}
                path={menuPageConfig.linkPath ?? menuPageConfig.path}
                search={menuPageConfig.search}
                disabledOnActive={menuPageConfig.lateralMenuLinkConfig.disabledOnActive ?? false}
                title={menuPageConfig.lateralMenuLinkConfig.title}
                icon={menuPageConfig.icon}
                googleAnalyticsTagConfig={menuPageConfig.googleAnalyticsTagConfig}
                closeMenu={closeMenu}
              />
            ))}
      </LateralMenuTopContainer>
      <LateralMenuBottomContainer>
        {menuButtons.map((menuButton) => (
          <LateralMenuButton
            key={menuButton.label}
            label={menuButton.label}
            icon={menuButton.icon}
            onButtonClick={menuButton.onButtonClick}
          />
        ))}
      </LateralMenuBottomContainer>
      <HelpAndContactSidePanel
        onClose={() => setIsHelpAndContactSidePanelOpened(false)}
        isOpen={isHelpAndContactSidePanelOpened}
      />
    </LateralMenuContainer>
  );
};
