import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';
import { colorPalette, device, getSpacing, typography } from 'stylesheet';

interface DrawerStyleProps {
  widthInPercent?: number;
  isMobile?: boolean;
}

export const useDrawerStyle = ({ widthInPercent, isMobile }: DrawerStyleProps) =>
  makeStyles({
    paper: {
      ...(widthInPercent ? { width: `${widthInPercent}%` } : { minWidth: `40%`, maxWidth: '80%' }),

      paddingLeft: isMobile ? getSpacing(0.5) : getSpacing(5),
      paddingRight: isMobile ? getSpacing(0.5) : getSpacing(5),
      marginTop: isMobile ? getSpacing(2) : getSpacing(0),
      marginBottom: isMobile ? getSpacing(10) : getSpacing(0),
      width: isMobile ? getSpacing(14) : getSpacing(75),
      borderRadius: isMobile ? 10 : 0,

      paddingBottom: getSpacing(2),
      paddingTop: getSpacing(2),
      backgroundColor: colorPalette.backgroundMain,
    },
  });

export const SidePanelHeader = styled.div`
  min-height: ${getSpacing(11)};
  border-bottom: 1px solid ${colorPalette.grey_400};
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media ${device.tabletMax} {
    justify-content: flex-end;
    min-height: ${getSpacing(7)};
  }
`;

export const TitleIconAndTitleContainer = styled.div`
  display: flex;
  align-items: center;
  ${typography.title_xsmall};
`;

export const TitleIconContainer = styled.div`
  width: ${getSpacing(5)};
  height: ${getSpacing(5)};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colorPalette.greyMain};
  @media ${device.tabletMax} {
    margin-right: ${getSpacing(3)};
  }
  margin-right: ${getSpacing(2)};
`;
