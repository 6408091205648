import styled from 'styled-components';
import { colorPalette, device, getSpacing, typography } from 'stylesheet';

export const HomePageContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  @media ${device.tabletMax} {
    margin-top: 150px;
  }
`;

export const HomeImage = styled.img`
  width: 215px;
  height: 161px;
`;

export const Title = styled.h1`
  margin-top: ${getSpacing(4)};
  ${typography.title_small};
  margin-bottom: ${getSpacing(10)};
  > #highlightedName {
    text-transform: capitalize;
    color: ${colorPalette.secondary_600};
  }
  @media ${device.laptopMax} {
    margin-bottom: 5%;
  }
`;

export const MenuSectionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${getSpacing(5)};
  @media ${device.mobileMax} {
    flex-direction: column;
    gap: ${getSpacing(0)};
  }
  @media ${device.laptopMax} and ${device.mobileMin} {
    flex-wrap: wrap;
    justify-content: center;
    gap: ${getSpacing(2)};
  }
`;
