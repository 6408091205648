import { Configuration, PublicClientApplication } from '@azure/msal-browser';
import { OIDC_DEFAULT_SCOPES } from '@azure/msal-common';
import { ROUTES } from 'routing/routes';
import {
  AZURE_AD_B2C_CLIENT_ID,
  AZURE_AD_B2C_REDIRECTION_URI,
  AZURE_BACKEND_CLIENT_ID,
  AZURE_SIGN_IN_SIGN_UP_POLICY,
  AZURE_TENANT_NAME,
  PLATFORM_LANGUAGE,
  PLATFORM_LEGAL_CORPORATION_ID,
} from 'services/api/constants';

const MSAL_CONFIG: Configuration = {
  auth: {
    clientId: AZURE_AD_B2C_CLIENT_ID,
    authority: `https://${AZURE_TENANT_NAME}.b2clogin.com/${AZURE_TENANT_NAME}.onmicrosoft.com/${AZURE_SIGN_IN_SIGN_UP_POLICY}`,
    redirectUri: AZURE_AD_B2C_REDIRECTION_URI,
    postLogoutRedirectUri: AZURE_AD_B2C_REDIRECTION_URI + ROUTES.HOME,
    knownAuthorities: [`${AZURE_TENANT_NAME}.b2clogin.com`],
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

export const msalInstance = new PublicClientApplication(MSAL_CONFIG);

export const ACCESS_TOKEN_SCOPES = [
  `https://${AZURE_TENANT_NAME}.onmicrosoft.com/${AZURE_BACKEND_CLIENT_ID}/Access`,
];
export const PLATFORM_REVISED_LEGAL_CORPORATION_ID = AZURE_AD_B2C_REDIRECTION_URI.match(
  /reevescc/gi,
)
  ? PLATFORM_LEGAL_CORPORATION_ID + 'REEVES'
  : PLATFORM_LEGAL_CORPORATION_ID;
export const MSAL_REDIRECT_PARAMETERS = {
  scopes: OIDC_DEFAULT_SCOPES,
  extraQueryParameters: {
    sj: PLATFORM_REVISED_LEGAL_CORPORATION_ID,
    ui_locales: PLATFORM_LANGUAGE,
  },
};
